import React from "react";
import { ContainerButton } from "./styles";

type Props = {
    name: string | null | undefined;
    email: string | null | undefined;
    cargo: string | null | undefined;
    setStepOneActive: (val: boolean) => void;
    setStepTwoActive: (val: boolean) => void;
}

const StepOne: React.FC<Props> = ({name, email, cargo, setStepOneActive, setStepTwoActive}) => {
    /*const cargos: string[] = ['Analista Financeiro','Analista de Suporte','Assistente de Coordenação',
        'Assistente de Direção', 'Coordenador', 'Coordenadora', 'Diretor', 'Diretora', 'Professor',
        'Professora', 'Supervisor', 'Supervisora', 'Secretária', 'Secretário']*/
    
    function handleSignatureGen(): void {
        if(cargo) {
            setStepOneActive(false);
            setStepTwoActive(true);
        } else alert('Por favor, selecione seu cargo e tente novamente.');
    }

    return <>
        <h2>Confirme Seus Dados</h2>
        <form onSubmit={e => e.preventDefault()} style={{textAlign:'center'}}>
            <p style={{marginBottom: '0'}}><b>Nome:</b> {name}</p>
            <p style={{marginBottom: '0', marginTop: '0'}}><b>E-mail:</b> {email}</p>
            <p style={{marginBottom: '0', marginTop: '0'}}><b>Cargo:</b> {cargo}</p>
            <p><b>Seus dados estão incorretos?</b><br/>Solicite ao seu administrador de contas Google as correções necessárias.</p>
            <p><b>Seus dados estão corretos?</b><br/>Clique no botão abaixo para continuar.</p>
            <ContainerButton>
                <button type="submit" onClick={handleSignatureGen} className="btnGen">Gerar Assinatura</button>
            </ContainerButton>
        </form>
    </>
}
export default StepOne;