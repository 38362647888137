import React, { useState } from "react";
import { IUser } from '../../models/user.model';
import { Container } from './styles';
import StepOne from '../StepOne';
import StepTwo from '../StepTwo';
import Header from '../Header';

interface Props  {
    userInfo: IUser;
}

const SignatureGen: React.FC<Props> = ({userInfo}) => {
    const [stepOneActive, setStepOneActive] = useState<boolean>(true);
    const [stepTwoActive, setStepTwoActive] = useState<boolean>(false);

    return (
        <>
        <Header/>
        <Container>
            {
                stepOneActive &&
                <StepOne 
                    name={userInfo.name} 
                    email={userInfo.email} 
                    cargo={userInfo.position} 
                    setStepOneActive={setStepOneActive}
                    setStepTwoActive={setStepTwoActive}
                />
            }
            {
                stepTwoActive &&
                <StepTwo 
                    name={userInfo.name} 
                    email={userInfo.email} 
                    cargo={userInfo.position} 
                    setStepOneActive={setStepOneActive}
                    setStepTwoActive={setStepTwoActive}
                />
            }
        </Container>
        </>
    )
}
export default SignatureGen;