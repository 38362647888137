import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    width: 700px;
    margin: 50px auto;
    border-radius: 20px;
    background-color: #de1533;
    color: #fff;

    button {
        background-color: #fff;
        color: #000;
        padding: 10px 20px;
        border: none;
        border-radius: 10px;
        margin-top: 30px;
        cursor: pointer;
        font-weight: bold;

        &:active {
            background-color: #eee;
        }
    }
`;