import React from "react";
import { Container } from "./styles";
import Header from '../Header';

const Forbiden: React.FC = () => {
    return (
        <>
        <Header/>
        <Container>
            <h1>Acesso Restrito</h1>
            <p>Apenas e-mails com o domínio <b>@virtualrb.com.br</b> podem acessar esta aplicação.</p>
            <p>Por favor, faça login utilizando sua conta <b>@virtualrb.com.br</b>.</p>
            <button onClick={() => window.location.reload()}>Tentar Novamente</button>
        </Container>
        </>
    )
}
export default Forbiden;