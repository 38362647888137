import React, { useState } from 'react';
import { IUser } from './models/user.model';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import Forbiden from './components/Forbiden';
import SignatureGen from './components/SignatureGen';
import logo from "./assets/logo.svg";


const App: React.FC = () => {
  const clientId: string = "824112237322-55cecn5o67kc8obf37t58badmmlpuh1d.apps.googleusercontent.com";
  const [userInformation, setUserInformation] = useState<IUser>({});
  const [loginSuccess, setLoginSuccess] = useState<'success' | 'fail' | null>(null);

  function onLoginSuccess(response: any): void {
    interface GResponse{
      given_name: string
      family_name: string,
      email: string
    }
  
    const profileObj: GResponse = jwt_decode(response.credential);

    if(profileObj.email.endsWith('@virtualrb.com.br')) {
      setLoginSuccess('success');
      setUserInformation({email: profileObj.email, name: profileObj.given_name, position: profileObj.family_name.replace('(', '').replace(')', '')});
    } else {
      setLoginSuccess('fail');
    }
  }

  if(loginSuccess === 'success') return <SignatureGen userInfo={userInformation} />;
  if(loginSuccess === 'fail') return <Forbiden />;
  
  return (
    <div style={{display: 'flex', flexDirection: 'column', margin: '100px auto', width: '15%', boxShadow: '0px 0px 5px 2px #777', padding: '30px', borderRadius:'10px', alignItems: 'center'}}>
      <img src={logo} alt="Logo Red Balloon" height="80"/>
      <p style={{textAlign: 'center'}}><b>Gerador de Assinatura</b></p>
      <div>
        <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          onSuccess={onLoginSuccess}
          onError={() => {
            console.log('Login Failed');
          }}
        />
        </GoogleOAuthProvider>
      </div>
    </div>
  )
}

export default App;