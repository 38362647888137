import React, { useState } from 'react';
// import { toPng } from 'html-to-image';
import { Card, ContainerButtons } from './styles'
import { htmlCard } from '../../helpers/htmlCard';
import Logo from '../Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import ModalCopyHTML from '../ModalCopyHTML';

type Props = {
    name: string | null | undefined;
    email: string | null | undefined;
    cargo: string | null | undefined;
    setStepOneActive: (val: boolean) => void;
    setStepTwoActive: (val: boolean) => void;
}

const StepTwo: React.FC<Props> = ({name, email, cargo, setStepTwoActive, setStepOneActive}) => {
  const [modal, setModal] = useState<boolean>(false);
  const htmlUser: string = htmlCard(name, cargo, email);
  
  /****GERADOR DE IMAGEM ***
  - Crie uma div pai acima da tar Card com a ref={ref}, e um botão que chama a função
  onButtonClick

  const ref = useRef<HTMLDivElement>(null);

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return
    }

    toPng(ref.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'my-card.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [ref])
  */

  function handleBackButton(): void {
    setStepOneActive(true);
    setStepTwoActive(false);
  }

  return (
    <>
      <h2>Assinatura gerada com sucesso!</h2>
        <Card>
            <div className="container-logo">
                <Logo/>
            </div>
            <div className="container-info">
                <div className="container-info--text">
                    <h3>{name}</h3>
                    <p style={{fontSize:'14px', margin: '0 0 5px'}}>{cargo}</p>
                    <a href={`mailto:${email}`}>{email}</a>
                    <a href="http://redballon.com.br">www.redballoon.com.br</a>
                </div>
                <div className="container-info--icon">
                    <a href="https://www.facebook.com/redballoonBR">
                      <FontAwesomeIcon icon={faFacebook} className="icon"/>
                    </a>
                    <a href="https://www.instagram.com/redballoonbr/">
                      <FontAwesomeIcon icon={faInstagram} className="icon"/>
                    </a>
                    <a href="https://www.youtube.com/channel/UCZdkAk4QW9dtaiwDO_APJCQ">
                      <FontAwesomeIcon icon={faYoutube} className="icon"/>
                    </a>
                    <a href="https://www.linkedin.com/company/red-balloon-in-school/">
                      <FontAwesomeIcon icon={faLinkedin} className="icon"/>
                    </a>
                </div>
            </div>
        </Card>

      <ModalCopyHTML
        show={modal}
        setModal={setModal}
        htmlCard={htmlUser}
      />

      <ContainerButtons>
        <button onClick={handleBackButton} className="btn-back">Voltar</button>
        <button onClick={() => setModal(true)} className="btn">Código HTML</button>
      </ContainerButtons>
    </>
  )
}
export default StepTwo;