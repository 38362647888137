import styled from "styled-components";

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0, .8);
    color: #000;
`;

export const ModalBox = styled.div`
    display:flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px 20px;
    width: 500px;
    justify-content: space-evenly;
    border-radius: 10px;
    padding: 40px 40px 20px;

    p {
        margin-bottom: 0;
    }

    .btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 10px;
        margin-top: 10px;

        &-hide {
            background-color: #fff;
            color: #de1533;
            border: 1px solid #de1533;
            border-radius: 10px;
            padding: 10px 20px;
            cursor: pointer;
            font-weight: bold;

            &:active {
                background-color: #eee;
            }
        }
        &-copy {
            background-color: #de1533;
            color: #fff;
            border: none;
            border-radius: 10px;
            padding: 5px 20px;
            cursor: pointer;
            margin-left: 10px;
            font-weight: bold;

            &:active {
                background-color: #f25469;
            }
        }
`;
