import React from "react";
import logo from '../../assets/rb-logo-white.png'
import { HeaderDiv } from "./styles";

const Header: React.FC = () => {
    return <HeaderDiv>
        <img src={logo} alt="logo red balloon" />
        <p>
            <b>
                Gerador de Assinatura
            </b>
        </p>
    </HeaderDiv>
}
export default Header;