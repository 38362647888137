import styled from 'styled-components';

export const HeaderDiv = styled.header`
    height: 50px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    background-color: #de1533;
    align-items: center;

    img {
        height: 40px;
    }

    p {
        padding-left: 20px;
        color: #fff;
    }
`;