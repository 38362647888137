import React, { useState } from 'react';
import { ModalContainer, ModalBox } from './styles';

interface Props {
    show: boolean; 
    setModal: (val:boolean) => void;
    htmlCard: string;
}

const ModalCopyHTML: React.FC<Props> = ({ show, setModal, htmlCard }) => {
    const [copied, setCopied] = useState<boolean>(false);

    function handleCopyHTML(): void {
        navigator.clipboard.writeText(htmlCard);
        setCopied(true);
    }
    function handleCloseModal(): void {
        setModal(false);
        setCopied(false);
    }

    return (
        <ModalContainer style={{display: show ? 'flex': 'none'}}>
            <ModalBox>
                <textarea name="codigoHTML" id="codigoHTML" readOnly={true} value={htmlCard} disabled={true} rows={5}/>
                {copied ?
                    <p>Pronto!<br/>Agora cole o HTML onde você deseja inserir sua assinatura utilizando Ctrl+V, Command+V ou o clique direito do mouse.</p>
                    :
                    <p><br/>Clique no botão 'Copiar HTML' para avançar.</p>
                }
                <div className="btn">
                    <button onClick={handleCloseModal} className="btn-hide">Fechar</button>
                    <button onClick={handleCopyHTML} className="btn-copy">{copied ? 'Copiado!' : 'Copiar HTML'}</button>
                </div>
            </ModalBox>
        </ModalContainer>
    );
}

export default ModalCopyHTML;