import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    width: 700px;
    margin: 50px auto;
    border-radius: 20px;
    background-color: #de1533;
    color: #fff;

    h2 {
        margin: 0 auto 20px;
        text-align: center;
    }
`;