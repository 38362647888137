import styled from 'styled-components';

export const Select = styled.select`
    padding: 8px;
    border-radius: 10px;
    border: none;
    background-color: #fff;
`;

export const ContainerButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .btnGen {
        background-color: #fff;
        color: #000;
        padding: 10px 20px;
        border: none;
        border-radius: 10px;
        margin-top: 20px;
        cursor: pointer;
        font-weight: bold;

        &:active {
            background-color: #eee;
        }
    }
`;