import React from "react"

const Logo: React.FC= () => {
    return <>
        <svg height="120" viewBox="0 0 578.66669 361.33334">
            <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,361.33333)">
                <g id="g12">
                    <g id="g14" clip-path="url(#clipPath18)">
                        <g id="g20" transform="translate(169.9141,147.0723)">
                            <path
                                d="m 0,0 c 15.361,2.463 28.352,8.811 39.469,21.087 11.879,13.11 19.105,30.323 18.179,45.613 -0.019,0.325 0.207,0.392 0.34,0.098 C 65.5,50.28 62.609,30.133 49.545,15.707 37.057,1.917 18.434,-3.128 0.068,-0.378 -0.346,-0.316 -0.391,-0.062 0,0 m -58.727,-16.75 c -0.627,0.556 -1.273,1.085 -1.925,1.604 -4.362,-1.038 -9.442,-2.145 -8.793,-4.219 1.088,-3.483 16.256,-7.86 18.746,-6.618 2.488,1.239 0.644,5.268 1.849,7.351 0.079,0.133 0.276,0.209 0.541,0.251 0.362,0.023 0.725,0.034 1.073,0.03 0.58,-0.024 1.203,-0.086 1.67,-0.113 85.734,-7.854 123.072,34.255 120.343,74.52 -3.107,45.875 -71.976,67.967 -117.39,25.107 -37.59,-35.476 -22.694,-74.005 -14.762,-88.434 2.863,-1.628 5.605,-3.57 8.187,-5.854 1.708,-1.511 -7.388,-5.527 -9.539,-3.625"
                                style={{fill: '#de1533', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path22"
                            />
                        </g>
                        <g id="g24" transform="translate(93.8652,31.5811)">
                            <path
                                d="m 0,0 c -32.432,-8.332 -61.133,22.748 -61.877,54.654 -0.436,18.686 9.555,37.318 24.203,48.041 16.014,11.725 37.904,9.677 53.07,-2.35 2.961,0.704 5.59,1.376 5.864,2.295 0.263,0.219 0.086,0.916 -0.227,1.611 -0.635,0.979 -1.453,2.319 -2.359,3.969 -15.11,8.591 -33.615,8.347 -49.994,2.412 -18.463,-6.692 -33.276,-23.192 -38.674,-42.534 -9.485,-33.982 14.693,-69.47 48.219,-73.56 7.58,-0.925 15.316,0.094 22.679,1.985 0.926,0.238 5.053,1.464 4.619,3.139 C 5.082,1.359 0.93,0.239 0,0"
                                style={{fill: '#de1533', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path26"
                            />
                        </g>
                        <g id="g28" transform="translate(115.125,134.2207)">
                            <path
                                d="m 0,0 c -0.273,-0.919 -2.902,-1.591 -5.863,-2.295 0.652,-0.518 1.299,-1.048 1.925,-1.603 2.151,-1.903 11.247,2.114 9.54,3.625 C 3.02,2.011 0.277,3.952 -2.586,5.58 -1.68,3.931 -0.861,2.591 -0.227,1.611 0.086,0.916 0.264,0.22 0,0"
                                style={{fill: '#de1533', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path30"
                            />
                        </g>
                        <g id="g32" transform="translate(202.8945,109.4248)">
                            <path
                                d="m 0,0 h -2.068 c -4.137,0 -6.477,-2.429 -7.465,-6.656 l -10.973,-47.94 c -1.08,-4.766 1.258,-8.365 6.205,-8.365 h 2.069 c 4.226,0 6.476,2.519 7.464,6.746 L 6.205,-8.365 C 7.285,-3.598 4.947,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path34"
                            />
                        </g>
                        <g id="g36" transform="translate(226.0996,109.4248)">
                            <path
                                d="m 0,0 c -4.137,0 -6.477,-2.429 -7.465,-6.656 l -10.972,-47.94 c -1.081,-4.766 1.257,-8.365 6.205,-8.365 h 2.07 c 4.226,0 6.474,2.519 7.463,6.746 L 8.275,-8.365 C 9.355,-3.598 7.016,0 2.07,0 Z"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path38"
                            />
                        </g>
                        <g id="g40" transform="translate(260.457,83.6113)">
                            <path
                                d="m 0,0 c 3.957,0 6.387,-2.608 6.387,-8.815 0,-8.995 -3.688,-18.528 -10.524,-18.528 -4.138,0 -6.656,2.519 -6.656,8.545 C -10.793,-9.894 -6.746,0 0,0 m -4.766,-37.776 c 16.28,0 27.073,11.602 27.073,28.332 0,12.502 -8.186,20.417 -21.768,20.417 -15.74,0 -27.072,-11.963 -27.072,-28.693 0,-12.501 8.096,-20.056 21.767,-20.056"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path42"
                            />
                        </g>
                        <g id="g44" transform="translate(319.5508,74.7959)">
                            <path
                                d="m 0,0 c 0,-8.994 -3.688,-18.527 -10.523,-18.527 -4.139,0 -6.657,2.518 -6.657,8.545 0,8.904 4.047,18.797 10.793,18.797 C -2.428,8.815 0,6.207 0,0 m -32.92,-8.904 c 0,-12.501 8.094,-20.057 21.768,-20.057 16.279,0 27.072,11.603 27.072,28.332 0,12.502 -8.186,20.417 -21.766,20.417 -15.742,0 -27.074,-11.963 -27.074,-28.692"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path46"
                            />
                        </g>
                        <g id="g48" transform="translate(106.5664,84.1504)">
                            <path
                                d="m 0,0 h -3.719 l 0.002,0.019 c -1.287,-0.071 -1.349,0.829 -1.297,1.287 l 2.377,10.593 c 0.207,0.625 0.828,2.004 2.291,2.132 h 2.953 c 4.768,0 7.016,-2.248 7.016,-5.756 C 9.623,2.068 5.307,0 0,0 m -4.408,-26.533 h -5.434 l 0.002,0.004 c -1.469,-0.039 -1.392,1.099 -1.334,1.45 l 2.908,12.465 c 0.168,0.555 0.791,2.145 2.37,2.27 h 4.187 c 4.947,0 7.465,-2.518 7.465,-6.655 0,-6.836 -4.317,-9.534 -10.164,-9.534 m 27.164,8.814 c 0,5.3 -2.324,8.855 -6.313,10.84 l 0.002,0.009 c -0.076,0.048 -0.377,0.184 -0.72,0.331 l -0.077,0.032 c -0.353,0.15 -0.738,0.303 -0.972,0.381 -0.483,0.161 -1.11,0.383 -0.99,0.927 0.084,0.375 0.962,0.698 1.345,0.86 6.408,2.203 10.873,6.494 10.873,14.412 0,9.715 -7.556,15.201 -21.588,15.201 h -13.67 c -4.767,0 -7.736,-2.698 -8.724,-6.924 l -11.154,-48.481 c -0.989,-4.227 1.349,-7.556 5.755,-7.556 h 18.26 c 15.291,0 27.973,5.937 27.973,19.968"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path50"
                            />
                        </g>
                        <g id="g52" transform="translate(159.7852,64.3604)">
                            <path
                                d="m 0,0 -1.113,-4.926 h -0.002 c -0.34,-1.527 -1.073,-2.29 -1.371,-2.547 -1.368,-1.02 -2.895,-1.609 -4.774,-1.609 -2.787,0 -4.316,1.619 -4.316,4.497 0,4.048 2.43,6.927 7.375,6.927 1.314,0 2.402,-0.136 3.373,-0.337 C -0.402,1.873 0.344,1.49 0,0 m 13.607,-11.24 4.948,21.316 c 3.238,13.942 -3.957,20.148 -18.258,20.148 -5.037,0 -9.715,-0.629 -12.953,-1.799 -3.688,-1.26 -5.756,-3.058 -6.385,-6.027 l -0.18,-0.899 c -0.631,-3.328 1.35,-5.576 4.227,-5.576 3.148,0 4.767,2.878 11.424,2.878 5.449,0 7.146,-2.588 6.148,-7.828 C 2.221,9.459 0.807,9.709 0.479,9.768 L 0.477,9.767 c -1.637,0.338 -3.606,0.579 -6.297,0.579 -12.502,0 -21.047,-5.487 -21.047,-16.459 0,-7.915 5.396,-12.323 12.863,-12.323 2.846,0 5.451,0.659 7.754,1.832 1.16,0.547 3.031,1.333 4.719,1.038 1.638,-0.287 1.722,-1.223 3.582,-1.852 0.617,-0.228 1.312,-0.379 2.088,-0.441 h 0.002 0.002 c 0.267,-0.022 0.543,-0.037 0.832,-0.037 h 1.437 c 3.777,0 6.117,2.159 7.195,6.656"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path54"
                            />
                        </g>
                        <g id="g56" transform="translate(378.7344,94.584)">
                            <path
                                d="m 0,0 c -4.848,0 -8.744,-1.332 -12.211,-3.444 l -0.004,0.002 c -0.129,-0.076 -0.308,-0.193 -0.519,-0.324 -0.03,-0.019 -0.061,-0.037 -0.09,-0.056 -0.715,-0.44 -1.783,-1.017 -2.893,-0.964 -2.553,0.122 -2.795,3.242 -6.972,3.861 -0.061,0.009 -0.12,0.018 -0.18,0.026 -0.084,0.011 -0.158,0.027 -0.244,0.036 l -0.01,-0.01 c -0.371,0.039 -0.756,0.063 -1.162,0.063 h -1.529 c -3.778,0 -6.206,-2.158 -7.196,-6.565 l -7.375,-32.11 c -1.08,-4.767 1.168,-8.635 6.117,-8.635 h 2.159 c 4.047,0 6.384,2.069 7.375,6.297 l 5.308,23.566 c 0.522,1.661 1.24,3.196 3.477,4.194 2.148,0.924 4.037,1.381 5.875,1.381 3.238,0 4.228,-1.619 3.418,-5.216 l -4.856,-21.498 c -1.08,-4.766 1.168,-8.724 6.116,-8.724 h 2.158 c 4.136,0 6.384,2.339 7.375,6.566 L 9.982,-16.1 C 12.412,-5.486 7.375,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path58"
                            />
                        </g>
                        <g id="g60" transform="translate(273.8262,139.7393)">
                            <path
                                d="M 0,0 H -1.432 L -1.42,0.008 C -5.092,0.16 -5.602,2.135 -5.23,3.802 l 0.001,0.005 2.286,9.823 c 0.047,0.19 0.089,0.362 0.117,0.46 0.512,1.859 1.889,2.779 4.773,2.909 h 1.291 c 5.848,0 7.826,-2.429 7.826,-6.386 C 11.064,3.777 7.197,0 0,0 m 19.818,-42.061 c 1.912,0.886 3.823,2.276 5.377,3.591 1.629,1.373 2.301,3.39 0.489,5.461 -1.256,1.408 -3.516,3.484 -5.93,8.215 -4.25,7.844 -5.305,13.524 -5.305,13.524 -0.224,1.229 -0.193,2.723 0.789,3.441 0.547,0.399 2.041,1.167 2.633,1.521 6.065,3.626 9.832,9.39 9.832,17.011 0,11.962 -7.285,18.078 -22.846,18.078 h -15.56 c -4.137,0 -6.475,-2.427 -7.465,-6.656 L -29.23,-25.904 c -1.081,-4.767 1.078,-8.275 5.845,-8.275 h 3.508 c 4.049,0 6.477,2.339 7.467,6.567 l 2.91,12.435 c 0.066,0.294 0.172,0.753 0.258,1.109 l 0.07,0.298 h 0.004 c 0.006,0.018 0.01,0.041 0.014,0.055 0.951,3.58 2.203,4.362 3.982,4.36 0.721,0 1.324,-0.339 1.822,-0.79 -0.005,-0.001 -0.015,-0.001 -0.015,-0.001 1.047,-0.969 1.58,-2.481 1.58,-2.481 l 0.494,-1.299 2.475,-6.558 -0.01,-0.003 c 0.252,-0.804 0.613,-1.791 1.055,-2.881 l 0.005,-0.015 v 10e-4 c 1.434,-3.608 3.717,-8.4 6.11,-11.822 1.207,-1.77 3.033,-4.084 5.367,-5.863 1.344,-1.04 3.81,-2.058 6.107,-0.994"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path62"
                            />
                        </g>
                        <g id="g64" transform="translate(384.4219,137.4668)">
                            <path
                                d="m 0,0 -4.049,-17.57 -0.002,-0.001 c -0.189,-0.714 -0.681,-2.16 -2.527,-3.157 -1.557,-0.761 -3.149,-1.194 -4.568,-1.194 -4.319,0 -6.118,2.428 -6.118,7.555 0,9.983 4.317,18.798 12.053,18.798 1.479,0 2.621,-0.269 3.541,-0.66 C -1.189,3.544 0.654,2.487 0,0 m 15.205,31.054 h -2.158 c -4.137,0 -6.477,-2.428 -7.465,-6.657 l -2.23,-9.733 c -0.668,-2.532 -2.733,-1.338 -3.1,-1.082 -2.201,1.542 -5.131,2.541 -9.15,2.541 -14.571,0 -24.645,-14.301 -24.645,-31.479 0,-12.592 7.016,-17.36 15.109,-17.36 3.768,0 6.975,1.197 9.604,3.107 l 0.017,-0.006 c 0,0 1.329,1.095 2.829,0.98 1.865,-0.143 2.048,-2.779 6.65,-3.237 0.162,-0.017 0.313,-0.019 0.455,-0.014 0.195,-0.01 0.391,-0.02 0.594,-0.02 h 1.619 c 3.777,0 6.025,2.068 7.016,6.385 l 11.062,48.21 c 1.078,4.767 -1.17,8.365 -6.207,8.365"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path66"
                            />
                        </g>
                        <g id="g68" transform="translate(320.1387,137.1748)">
                            <path
                                d="m 0,0 c 1.682,4.008 4.311,6.611 7.885,6.611 3.508,0 5.396,-2.158 5.396,-6.475 0,-0.497 -0.023,-1.017 -0.08,-1.548 h -0.002 c -0.23,-1.711 -2.054,-1.596 -2.054,-1.596 L 11.141,-3.013 H 1.428 L 1.43,-3.012 C -0.738,-2.939 -0.406,-0.961 0,0 m 4.197,-32.334 c 4.407,0 9.444,0.63 12.592,1.98 3.238,1.348 5.127,3.327 5.486,5.936 l 0.09,0.629 c 0.449,3.058 -1.168,5.307 -4.136,5.307 -3.598,0 -4.499,-3.148 -11.694,-3.148 -4.568,0 -7.262,1.603 -8.222,5.433 -0.532,2.785 0.771,4.591 3.759,4.73 h 18.764 c 3.869,0 5.576,2.248 6.027,5.127 0.27,1.889 0.449,3.508 0.449,5.306 0,10.704 -6.115,17.539 -18.439,17.539 -16.459,0 -26.713,-13.312 -26.713,-29.232 0,-12.861 7.826,-19.607 22.037,-19.607"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path70"
                            />
                        </g>
                        <g id="g72" transform="translate(124.5,37.6074)">
                            <path
                                d="m 0,0 h -0.047 c -0.861,0 -1.43,-0.521 -1.701,-1.725 l -2.813,-12.138 c -0.318,-1.407 0.317,-2.246 1.702,-2.246 h 0.045 c 0.863,0 1.429,0.544 1.701,1.747 L 1.678,-2.314 C 2.02,-0.862 1.383,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path74"
                            />
                        </g>
                        <g id="g76" transform="translate(135.6836,33.7051)">
                            <path
                                d="m 0,0 c -1.861,0 -3.178,-0.727 -4.379,-1.679 -0.023,0.976 -0.523,1.611 -1.498,1.611 h -0.045 c -0.818,0 -1.408,-0.477 -1.656,-1.588 l -1.885,-8.282 c -0.363,-1.566 0.25,-2.269 1.522,-2.269 h 0.021 c 0.863,0 1.361,0.477 1.611,1.588 l 1.567,6.897 c 1.224,0.749 2.201,1.09 3.039,1.09 0.726,0 1.021,-0.431 0.818,-1.385 l -1.316,-5.899 c -0.363,-1.565 0.226,-2.291 1.498,-2.291 h 0.021 c 0.864,0 1.362,0.477 1.612,1.588 l 1.519,6.602 C 3.018,-1.521 1.859,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path78"
                            />
                        </g>
                        <g id="g80" transform="translate(146.8184,25.5596)">
                            <path
                                d="m 0,0 c -0.906,-0.727 -1.838,-1.135 -2.723,-1.135 -0.974,0 -1.474,0.568 -1.474,1.793 0,2.632 1.111,5.309 3.517,5.309 1.02,0 1.52,-0.431 1.86,-0.772 z M 3.154,8.077 H 3.109 C 2.52,8.077 2.02,7.714 1.703,7.079 1.066,7.601 0.113,8.146 -1.135,8.146 c -3.72,0 -6.351,-3.336 -6.351,-7.625 0,-2.745 1.632,-3.97 3.56,-3.97 1.362,0 2.61,0.612 3.496,1.565 l -0.297,-1.293 c -0.384,-1.724 -1.609,-2.268 -3.199,-2.268 -1.859,0 -2.154,0.998 -2.994,0.998 -0.613,0 -0.998,-0.341 -1.156,-0.931 L -8.1,-5.469 c -0.205,-0.794 0.114,-1.452 0.885,-1.792 0.953,-0.408 2.02,-0.59 3.336,-0.59 3.381,0 5.627,1.112 6.443,4.606 l 2.043,8.872 c 0.34,1.52 -0.115,2.45 -1.453,2.45"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path82"
                            />
                        </g>
                        <g id="g84" transform="translate(156.6387,37.6074)">
                            <path
                                d="m 0,0 h -0.023 c -0.725,0 -1.315,-0.477 -1.565,-1.588 l -2.791,-12.207 c -0.363,-1.565 0.25,-2.314 1.52,-2.314 h 0.023 c 0.84,0 1.361,0.476 1.611,1.61 L 1.566,-2.291 C 1.93,-0.726 1.248,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path86"
                            />
                        </g>
                        <g id="g88" transform="translate(165.418,37.6758)">
                            <path
                                d="M 0,0 H -0.113 C -0.885,0 -1.52,-0.272 -2.27,-0.885 l -0.113,-0.091 c -1.496,-1.203 -0.816,-2.45 0.047,-2.45 h 0.045 c 0.385,0 0.703,0.227 1.088,0.544 l 0.818,0.636 0.477,-0.499 c 0.385,-0.386 0.724,-0.681 1.179,-0.681 h 0.022 c 1.021,0 1.408,1.202 0.5,2.337 L 1.701,-0.976 C 1.225,-0.363 0.613,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path90"
                            />
                        </g>
                        <g id="g92" transform="translate(166.0312,28.8271)">
                            <path
                                d="m 0,0 h -4.197 c 0.429,1.679 1.338,2.768 2.654,2.768 0.998,0 1.633,-0.613 1.633,-1.883 C 0.09,0.612 0.068,0.317 0,0 m -1.34,4.878 c -3.971,0 -6.351,-3.517 -6.351,-7.374 0,-3.199 1.859,-4.879 5.15,-4.879 1.02,0 2.201,0.159 2.926,0.477 0.703,0.318 1.158,0.704 1.271,1.316 l 0.022,0.113 c 0.16,0.749 -0.18,1.293 -0.885,1.293 -0.885,0 -0.998,-0.885 -2.971,-0.885 -1.498,0 -2.293,0.705 -2.293,2.61 v 0.454 h 5.832 c 0.953,0 1.362,0.545 1.475,1.248 0.068,0.477 0.113,0.907 0.113,1.339 0,2.609 -1.406,4.288 -4.289,4.288"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path94"
                            />
                        </g>
                        <g id="g96" transform="translate(178.4609,33.002)">
                            <path
                                d="m 0,0 c -0.682,0.386 -1.771,0.635 -2.973,0.635 -2.996,0 -4.834,-1.52 -4.834,-4.016 0,-1.838 1.09,-2.655 3.086,-3.358 1.09,-0.386 1.567,-0.636 1.567,-1.271 0,-0.84 -0.864,-1.179 -1.907,-1.179 -1.837,0 -2.087,1.066 -2.927,1.066 -0.522,0 -0.953,-0.295 -1.088,-0.862 L -9.1,-9.076 c -0.203,-0.772 0,-1.43 0.772,-1.838 0.748,-0.386 1.974,-0.612 3.176,-0.612 3.132,0 5.06,1.61 5.06,4.197 0,1.906 -1.316,2.632 -3.107,3.267 -1.227,0.431 -1.635,0.704 -1.635,1.249 0,0.725 0.568,1.133 1.725,1.133 1.611,0 1.861,-0.884 2.677,-0.884 0.614,0 0.953,0.408 1.135,0.953 l 0.022,0.068 C 0.93,-0.908 0.725,-0.409 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path98"
                            />
                        </g>
                        <g id="g100" transform="translate(190.1172,24.4248)">
                            <path
                                d="m 0,0 c -0.545,-0.521 -1.088,-0.885 -1.883,-0.885 -0.863,0 -1.316,0.499 -1.316,1.362 0,1.157 0.771,1.973 2.269,1.973 0.614,0 1.067,-0.091 1.43,-0.181 z M 0.023,9.28 C -1.225,9.28 -2.336,9.099 -3.107,8.781 -3.879,8.486 -4.311,8.077 -4.424,7.487 L -4.447,7.374 c -0.158,-0.748 0.25,-1.248 0.908,-1.248 0.748,0 1.135,0.771 2.994,0.771 1.475,0 1.93,-0.816 1.566,-2.381 L 0.93,4.084 c -0.475,0.159 -1.043,0.272 -2.065,0.272 -3.131,0 -5.172,-1.52 -5.172,-4.106 0,-2.088 1.338,-3.199 3.221,-3.199 1.248,0 2.225,0.476 2.949,1.179 0.16,-0.726 0.637,-1.157 1.43,-1.157 H 1.34 c 0.838,0 1.383,0.454 1.656,1.611 l 1.27,5.491 C 5.082,7.737 3.359,9.28 0.023,9.28"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path102"
                            />
                        </g>
                        <g id="g104" transform="translate(205.4277,23.9482)">
                            <path
                                d="m 0,0 c -0.977,0 -1.52,0.363 -1.861,0.681 l 1.293,5.65 C 0.34,6.966 1.111,7.307 1.975,7.307 2.859,7.307 3.471,6.626 3.471,5.287 3.471,2.201 2.314,0 0,0 m 3.244,9.757 c -1.361,0 -2.631,-0.703 -3.517,-1.634 -0.045,0.953 -0.543,1.565 -1.543,1.565 h -0.067 c -0.818,0 -1.408,-0.476 -1.656,-1.587 L -6.24,-3.834 C -6.604,-5.4 -5.9,-6.126 -4.652,-6.126 h 0.023 c 0.84,0 1.338,0.499 1.588,1.611 l 0.75,3.313 c 0.59,-0.545 1.496,-0.999 2.926,-0.999 3.562,0 6.127,2.723 6.127,7.555 0,3.132 -1.588,4.403 -3.518,4.403"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path106"
                            />
                        </g>
                        <g id="g108" transform="translate(219.0371,24.4248)">
                            <path
                                d="m 0,0 c -0.545,-0.521 -1.09,-0.885 -1.885,-0.885 -0.861,0 -1.314,0.499 -1.314,1.362 0,1.157 0.771,1.973 2.267,1.973 0.614,0 1.067,-0.091 1.43,-0.181 z M 0.021,9.28 C -1.227,9.28 -2.338,9.099 -3.109,8.781 -3.881,8.486 -4.312,8.077 -4.426,7.487 L -4.447,7.374 c -0.16,-0.748 0.248,-1.248 0.906,-1.248 0.75,0 1.135,0.771 2.996,0.771 1.475,0 1.928,-0.816 1.565,-2.381 L 0.93,4.084 c -0.477,0.159 -1.043,0.272 -2.065,0.272 -3.131,0 -5.174,-1.52 -5.174,-4.106 0,-2.088 1.34,-3.199 3.223,-3.199 1.248,0 2.223,0.476 2.949,1.179 0.158,-0.726 0.635,-1.157 1.43,-1.157 h 0.045 c 0.84,0 1.385,0.454 1.656,1.611 L 4.266,4.175 C 5.082,7.737 3.357,9.28 0.021,9.28"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path110"
                            />
                        </g>
                        <g id="g112" transform="translate(232.7363,33.6592)">
                            <path
                                d="m 0,0 c -1.111,0 -2.178,-0.839 -3.039,-2.042 0.113,1.203 -0.387,2.02 -1.498,2.02 h -0.045 c -0.818,0 -1.408,-0.477 -1.656,-1.588 l -1.907,-8.328 c -0.363,-1.542 0.272,-2.223 1.543,-2.223 h 0.022 c 0.863,0 1.385,0.499 1.635,1.61 l 1.474,6.445 c 0.68,0.567 1.498,0.975 2.926,1.134 1.248,0.136 1.658,0.817 1.658,1.77 v 0.045 C 1.113,-0.431 0.703,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path114"
                            />
                        </g>
                        <g id="g116" transform="translate(241.8555,32.5928)">
                            <path
                                d="m 0,0 c 0,0.658 -0.5,0.931 -1.09,0.931 h -1.043 l 0.227,0.998 c 0.363,1.566 -0.295,2.292 -1.522,2.292 h -0.021 c -0.748,0 -1.362,-0.499 -1.612,-1.612 L -5.445,0.931 h -0.569 c -0.793,0 -1.224,-0.477 -1.224,-1.248 v -0.045 c 0,-0.659 0.498,-0.931 1.088,-0.931 h 0.205 l -1.385,-5.99 c -0.543,-2.405 0.432,-3.88 2.928,-3.88 0.384,0 0.861,0.022 1.111,0.068 0.908,0.182 1.227,0.499 1.227,1.293 v 0.046 c 0,0.568 -0.274,0.862 -1.09,0.976 -0.817,0.113 -1.09,0.499 -0.885,1.429 l 1.383,6.058 h 1.429 c 0.795,0 1.227,0.477 1.227,1.248 z"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path118"
                            />
                        </g>
                        <g id="g120" transform="translate(246.1406,37.9482)">
                            <path
                                d="m 0,0 c -0.998,0 -1.885,-0.771 -1.885,-1.793 0,-0.93 0.682,-1.679 1.68,-1.679 0.998,0 1.906,0.749 1.906,1.792 C 1.701,-0.771 0.998,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path122"
                            />
                        </g>
                        <g id="g124" transform="translate(245.1191,33.6367)">
                            <path
                                d="M 0,0 H -0.023 C -0.748,0 -1.34,-0.453 -1.588,-1.565 l -1.885,-8.259 c -0.361,-1.566 0.229,-2.315 1.498,-2.315 h 0.024 c 0.84,0 1.383,0.499 1.633,1.611 l 1.882,8.237 C 1.928,-0.726 1.225,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path126"
                            />
                        </g>
                        <g id="g128" transform="translate(255.6445,33.6592)">
                            <path
                                d="m 0,0 c -1.111,0 -2.178,-0.839 -3.039,-2.042 0.113,1.203 -0.387,2.02 -1.498,2.02 h -0.045 c -0.818,0 -1.408,-0.477 -1.656,-1.588 l -1.907,-8.328 c -0.363,-1.542 0.272,-2.223 1.543,-2.223 h 0.022 c 0.863,0 1.385,0.499 1.635,1.61 l 1.474,6.445 c 0.68,0.567 1.498,0.975 2.926,1.134 1.248,0.136 1.658,0.817 1.658,1.77 v 0.045 C 1.113,-0.431 0.703,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path130"
                            />
                        </g>
                        <g id="g132" transform="translate(268.0957,24.6748)">
                            <path
                                d="m 0,0 c -0.771,-0.59 -1.633,-0.953 -2.336,-0.953 -1.225,0 -1.701,0.725 -1.701,2.224 0,2.971 1.314,5.263 3.56,5.263 0.932,0 1.454,-0.386 1.793,-0.703 z M 4.176,12.933 H 4.152 c -0.703,0 -1.314,-0.454 -1.564,-1.566 L 1.793,7.896 c -0.566,0.658 -1.43,1.112 -2.791,1.112 -3.652,0 -6.33,-3.449 -6.33,-7.874 0,-3.176 1.701,-4.379 3.834,-4.379 1.295,0 2.406,0.545 3.246,1.474 0.066,-0.907 0.658,-1.406 1.564,-1.406 H 1.34 c 0.861,0 1.361,0.499 1.611,1.611 l 2.791,12.208 c 0.362,1.565 -0.318,2.291 -1.566,2.291"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path134"
                            />
                        </g>
                        <g id="g136" transform="translate(279.3457,23.7666)">
                            <path
                                d="m 0,0 c -1.27,0 -2.086,0.727 -2.086,2.632 0,2.496 1.201,4.947 3.152,4.947 1.227,0 2.043,-0.771 2.043,-2.678 C 3.109,2.36 2.02,0 0,0 m 1.158,9.938 c -3.472,0 -6.49,-2.835 -6.49,-7.238 0,-3.062 1.906,-4.991 5.219,-4.991 3.654,0 6.467,2.723 6.467,7.124 0,3.063 -1.883,5.105 -5.196,5.105"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path138"
                            />
                        </g>
                        <g id="g140" transform="translate(295.1797,33.002)">
                            <path
                                d="m 0,0 c -0.682,0.386 -1.771,0.635 -2.973,0.635 -2.996,0 -4.834,-1.52 -4.834,-4.016 0,-1.838 1.09,-2.655 3.086,-3.358 1.09,-0.386 1.567,-0.636 1.567,-1.271 0,-0.84 -0.864,-1.179 -1.907,-1.179 -1.837,0 -2.087,1.066 -2.927,1.066 -0.522,0 -0.953,-0.295 -1.088,-0.862 L -9.1,-9.076 c -0.203,-0.772 0,-1.43 0.772,-1.838 0.748,-0.386 1.974,-0.612 3.176,-0.612 3.132,0 5.06,1.61 5.06,4.197 0,1.906 -1.316,2.632 -3.107,3.267 -1.227,0.431 -1.635,0.704 -1.635,1.249 0,0.725 0.568,1.133 1.725,1.133 1.611,0 1.861,-0.884 2.677,-0.884 0.614,0 0.953,0.408 1.135,0.953 l 0.022,0.068 C 0.93,-0.908 0.725,-0.409 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path142"
                            />
                        </g>
                        <g id="g144" transform="translate(307.6758,37.6982)">
                            <path
                                d="m 0,0 c -1.293,0 -2.359,-0.204 -3.199,-0.544 -0.977,-0.409 -1.385,-0.862 -1.475,-1.589 l -0.023,-0.204 c -0.069,-0.681 0.386,-1.18 1.021,-1.157 0.93,0 1.293,1.021 3.176,0.975 1.045,-0.022 2.043,-0.499 2.043,-1.882 0,-1.589 -0.998,-2.066 -3.086,-2.292 l -0.205,-0.023 c -1.225,-0.137 -1.496,-0.59 -1.496,-1.316 v -0.045 c 0,-0.658 0.295,-1.022 1.406,-1.248 l 0.227,-0.046 c 1.406,-0.317 1.906,-0.885 1.906,-2.042 0,-1.52 -1.248,-2.337 -2.928,-2.315 -2.267,0.046 -2.336,1.521 -3.515,1.521 -0.659,0 -1.09,-0.454 -1.18,-1.066 l -0.024,-0.16 c -0.113,-0.816 0.069,-1.338 0.817,-1.838 1.021,-0.634 2.359,-0.998 4.017,-0.998 3.448,0 6.217,1.77 6.217,4.789 0,1.678 -0.953,2.904 -2.406,3.29 v 0.089 c 2.064,0.455 3.518,1.838 3.518,3.995 C 4.811,-0.907 2.314,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path146"
                            />
                        </g>
                        <g id="g148" transform="translate(322.9863,24.4248)">
                            <path
                                d="m 0,0 c -0.545,-0.521 -1.09,-0.885 -1.885,-0.885 -0.861,0 -1.314,0.499 -1.314,1.362 0,1.157 0.771,1.973 2.267,1.973 0.614,0 1.067,-0.091 1.43,-0.181 z M 0.021,9.28 C -1.227,9.28 -2.338,9.099 -3.109,8.781 -3.881,8.486 -4.312,8.077 -4.426,7.487 L -4.447,7.374 c -0.16,-0.748 0.248,-1.248 0.906,-1.248 0.75,0 1.135,0.771 2.996,0.771 1.475,0 1.928,-0.816 1.565,-2.381 L 0.93,4.084 c -0.477,0.159 -1.043,0.272 -2.065,0.272 -3.131,0 -5.174,-1.52 -5.174,-4.106 0,-2.088 1.34,-3.199 3.223,-3.199 1.248,0 2.223,0.476 2.949,1.179 0.158,-0.726 0.635,-1.157 1.43,-1.157 h 0.045 c 0.84,0 1.385,0.454 1.656,1.611 L 4.266,4.175 C 5.082,7.737 3.357,9.28 0.021,9.28"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path150"
                            />
                        </g>
                        <g id="g152" transform="translate(338.0254,33.7051)">
                            <path
                                d="m 0,0 c -1.861,0 -3.178,-0.727 -4.379,-1.679 -0.023,0.976 -0.523,1.611 -1.498,1.611 h -0.045 c -0.818,0 -1.408,-0.477 -1.656,-1.588 l -1.885,-8.282 c -0.363,-1.566 0.25,-2.269 1.522,-2.269 h 0.021 c 0.863,0 1.361,0.477 1.611,1.588 l 1.567,6.897 c 1.224,0.749 2.201,1.09 3.039,1.09 0.726,0 1.021,-0.431 0.818,-1.385 l -1.316,-5.899 c -0.363,-1.565 0.226,-2.291 1.498,-2.291 h 0.021 c 0.864,0 1.362,0.477 1.612,1.588 l 1.519,6.602 C 3.018,-1.521 1.859,0 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path154"
                            />
                        </g>
                        <g id="g156" transform="translate(347.0723,23.7666)">
                            <path
                                d="m 0,0 c -1.27,0 -2.086,0.727 -2.086,2.632 0,2.496 1.201,4.947 3.152,4.947 1.227,0 2.043,-0.771 2.043,-2.678 C 3.109,2.36 2.02,0 0,0 m 1.158,9.938 c -3.472,0 -6.49,-2.835 -6.49,-7.238 0,-3.062 1.906,-4.991 5.219,-4.991 3.654,0 6.467,2.723 6.467,7.124 0,3.063 -1.883,5.105 -5.196,5.105"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path158"
                            />
                        </g>
                        <g id="g160" transform="translate(362.9062,33.002)">
                            <path
                                d="m 0,0 c -0.68,0.386 -1.77,0.635 -2.973,0.635 -2.994,0 -4.832,-1.52 -4.832,-4.016 0,-1.838 1.088,-2.655 3.086,-3.358 1.088,-0.386 1.565,-0.636 1.565,-1.271 0,-0.84 -0.862,-1.179 -1.907,-1.179 -1.837,0 -2.085,1.066 -2.925,1.066 -0.522,0 -0.953,-0.295 -1.09,-0.862 l -0.022,-0.091 c -0.205,-0.772 0,-1.43 0.772,-1.838 0.748,-0.386 1.972,-0.612 3.176,-0.612 3.13,0 5.06,1.61 5.06,4.197 0,1.906 -1.316,2.632 -3.109,3.267 -1.225,0.431 -1.633,0.704 -1.633,1.249 0,0.725 0.566,1.133 1.725,1.133 1.609,0 1.859,-0.884 2.675,-0.884 0.614,0 0.953,0.408 1.135,0.953 l 0.024,0.068 C 0.93,-0.908 0.727,-0.409 0,0"
                                style={{fill: '#005495', fillOpacity: '1', fillRule: 'nonzero', stroke: 'none'}}
                                id="path162"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </>

}
export default Logo;