import styled from 'styled-components';

export const Card = styled.div`
    height: 212px;
    width: 550px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    color: #000;

    .container-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 45%;
    }

    .icon {
        font-size: 24px;
        color: #de1533;
        margin: 10px 0;

        &:first-child {
            margin-left: 15px;
        }
    }

    .container-info {
        width: 55%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;

        &--text {
            border-left: 1px dotted #000;
            padding-left: 15px;
            h3 {
                margin: 0 0 5px;
                font-size: 16px;
            }

            h4 {
                margin: 0 0 5px;
                font-size: 14px;
            }

            a {
                display: block;
                margin: 0 0 5px;
                font-size: 14px;
                color: #005495;
            }
        }

    }
`;

export const ContainerButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;

    .btn {
        background-color: #fff;
        color: #000;
        padding: 10px 20px;
        border: none;
        border-radius: 10px;
        margin-left: 30px;
        cursor: pointer;
        font-weight: bold;

        &:active {
            background-color: #eee;
        }

        &-back {
            color: #000;
            background-color: #fff;
            padding: 10px 20px;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            font-weight: bold;

            &:active {
                background-color: #eee;
            }
        }
    }
`;